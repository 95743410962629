import React, { FunctionComponent } from 'react';

import { IntlProvider } from 'react-intl';
import { Translation } from './types';
import { defaultLanguage, messages, SupportedLanguage } from '../../config/messages';

export type IntlMessage = {
  language: SupportedLanguage;
  messages: Translation;
};

const provide = (language: SupportedLanguage): IntlMessage => {
  return {
    language,
    messages: messages[language] || messages[defaultLanguage],
  };
};

const MessagesProvider: FunctionComponent<any> = ({ children }) => {
  const language = (localStorage.getItem('language') || 'en') as SupportedLanguage;
  const intlMessage = provide(language);

  return (
    <IntlProvider
      formats={{
        number: {},
      }}
      locale={language}
      messages={intlMessage.messages}
    >
      {children}
    </IntlProvider>
  );
};

export default MessagesProvider;
