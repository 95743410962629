export const getBackendUrl = () => {
  // @ts-ignore
  console.log('injected env', window.injectedEnv);
  // @ts-ignore
  return window.injectedEnv.REACT_APP_BACKEND_URL;
};

// @TODO: Decouple capacity from OCTW!
export const OctwUrls = {
  getUrlForSearch: () => `/octw/search`,
  getUrlForRuleUpdate: () => `/octw/update`,
  getUrlForDefaultValueUpdate: () => `/v1/capacity/template`,
  getUrlForWeeklyValueUpdate: () => `/v1/capacity`,
};

export const CapacityUrls = {
  // @TODO: Change it
  getUrlForUpdateCarrier: () => `/v1/capacity/carrier`,
};
