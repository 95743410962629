import { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import https from 'https';
import axiosInstance from '../../../http/axiosSetup';
import RequestConfigProvider from '../../../http/RequestConfigProvider';

export const httpClient = () => {
  const getAxiosRequestConfig = async (
    method: Method,
    endpoint: string,
    configOverwrite?: AxiosRequestConfig,
  ): Promise<AxiosRequestConfig> => {
    const token = await RequestConfigProvider.getBearerToken();
    const headersWithToken = token ? { Authorization: `Bearer ${token}` } : undefined;

    const headers = {
      ...headersWithToken,
    };

    const config: AxiosRequestConfig = {
      url: endpoint,
      method,
      timeout: RequestConfigProvider.getTimeout(),
      httpsAgent: new https.Agent({
        // TODO: investigate why this is needed
        rejectUnauthorized: false,
      }),
      headers,
      ...configOverwrite,
    };

    return Promise.resolve(config);
  };

  return {
    get: async <T>(endpoint: string, configOverwrite?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
      const config = await getAxiosRequestConfig('GET', endpoint, configOverwrite);
      return axiosInstance.get(endpoint, config);
    },
    post: async <T>(
      endpoint: string,
      data: unknown,
      configOverwrite?: AxiosRequestConfig,
    ): Promise<AxiosResponse<T>> => {
      const config = await getAxiosRequestConfig('POST', endpoint, configOverwrite);
      return await axiosInstance.post(endpoint, data, config);
    },
    put: async <T>(
      endpoint: string,
      data: unknown,
      configOverwrite?: AxiosRequestConfig,
    ): Promise<AxiosResponse<T>> => {
      const config = await getAxiosRequestConfig('PUT', endpoint, configOverwrite);
      return await axiosInstance.put(endpoint, data, config);
    },
    delete: async <T>(endpoint: string, configOverwrite?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
      const config = await getAxiosRequestConfig('DELETE', endpoint, configOverwrite);
      return await axiosInstance.delete(endpoint, config);
    },
  };
};
