import React, { Dispatch, SetStateAction } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { McButton } from '@maersk-global/mds-react-wrapper';
import z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import useUsers from '../../capacity/hooks/useUsers';
import { AdjustableComponent, useClasses } from '../../common/hooks/useClasses';
import { FormattedMessage } from 'react-intl';
import FormikInputField from '../../common/components/FormikInputField/FormikInputField';
import Drawer from '../../common/components/Drawer/Drawer';

export type AddDefaultValuesDrawerStyles = {
  field: string;
  buttons: string;
  form: string;
};

export type AddDefaultValuesDrawerProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onSuccess?: Function;
  selectedTerminal: string;
  isGlobal: boolean;
};

export type EditRuleFormShape = {
  terminalId: string;
  email: string;
};

const FormValidationSchema = z.object({
  terminalId: z.string(),
  email: z.string(),
});

const FormValidationSchemaGlobal = z.object({
  email: z.string(),
});

const AddUserDrawer: AdjustableComponent<AddDefaultValuesDrawerProps, AddDefaultValuesDrawerStyles> = ({
  classes,
  open,
  setOpen,
  onSuccess,
  selectedTerminal,
  isGlobal,
}) => {
  const styles = useClasses(
    {
      field: 'AddDefaultValuesDrawer__field',
      buttons: 'AddDefaultValuesDrawer__buttons',
      form: 'AddDefaultValuesDrawer__form-wrapper',
    },
    classes,
  );

  const { addUser, addGlobalUser } = useUsers();

  const onClose = () => {
    setOpen(false);
  };

  const getFormInitialValues = (): EditRuleFormShape => {
    return {
      terminalId: selectedTerminal,
      email: '',
    };
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      if (isGlobal) {
        await addGlobalUser({ email: values.email });
      } else {
        await addUser({ terminal: selectedTerminal, email: values.email });
      }
      onSuccess?.();
      onClose();
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Drawer title={'Add user to ' + selectedTerminal} open={open} setOpen={setOpen} onRequestClose={onClose} noFooter>
      <Formik
        initialValues={getFormInitialValues()}
        enableReinitialize
        validationSchema={toFormikValidationSchema(isGlobal ? FormValidationSchemaGlobal : FormValidationSchema)}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form className={styles.form}>
            <div>
              <Field label={'Email'} type="text" name="email" component={FormikInputField} />
              <ErrorMessage name="email" component="div" />
              <Field type="hidden" name="terminalId" />
            </div>

            <div className={styles.buttons}>
              <McButton type="button" appearance="neutral" click={onClose}>
                <FormattedMessage id="cancel" />
              </McButton>
              <McButton disabled={isSubmitting} type="submit">
                <FormattedMessage id={isSubmitting ? 'updateRuleDrawerFormSubmitting' : 'updateRuleDrawerFormSubmit'} />
              </McButton>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default AddUserDrawer;
