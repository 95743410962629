import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { AccountInfo, InteractionType, InteractionStatus } from '@azure/msal-browser';
import { useEffect, useState } from 'react';
import { usersService } from '../service/users/users-service';
import { Maybe } from '../types';
import { env } from '../../../../env';

type UseAuthValues = {
  user: Maybe<string>;
  logout: () => void;
  token: Maybe<string>;
  unauthorized: boolean;
  isAdmin: boolean;
  switchAccount: () => void;
  userData: any;
  hasCTWAccess: boolean;
};

const ADMIN_ROLE_NAME = 'apmt-portdata-admin';
const USER_ROLE_NAME = 'apmt-portdata-user';

const useAuth = (): UseAuthValues => {
  useMsalAuthentication(InteractionType.Redirect);
  const { instance, inProgress } = useMsal();
  const [unauthorized, setUnauthorized] = useState<boolean>(false);
  const [user, setUser] = useState<string>();
  const [token, setToken] = useState<string>();
  const [account, setAccount] = useState<AccountInfo | null>(null);
  const [userData, setUserData] = useState<any>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [hasCTWAccess, setHasCTWAccess] = useState<boolean>(false);

  const switchAccount = async () => {
    try {
      await instance.acquireTokenRedirect({
        scopes: ['user.read'],
        prompt: 'select_account',
      });
    } catch (error) {
      console.log('Error during account switch: ', error);
    }
  };

  const logout = async () => {
    if (!account) {
      return;
    }
    const logoutRequest = {
      account: account,
      postLogoutRedirectUri: env.REACT_APP_REDIRECT_URL + '/',
    };
    await instance.logoutRedirect(logoutRequest);
    sessionStorage.clear();
  };

  useEffect(() => {
    const initializeInstance = async () => {
      const response = await instance.handleRedirectPromise();
      if (response) {
        setAccount(response.account);
      } else {
        const activeAccount = instance.getActiveAccount();
        if (activeAccount) {
          setAccount(activeAccount);
        } else {
          if (inProgress === InteractionStatus.None) {
            switchAccount();
          }
        }
      }
    };

    if (inProgress === InteractionStatus.None) {
      initializeInstance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- by design
  }, [instance, inProgress]);

  useEffect(() => {
    if (account) {
      try {
        const username = account.username;
        const token = account.idToken;
        const roles = account.idTokenClaims?.roles;
        setUser(username);
        const isCurrentUserMember = roles?.includes(USER_ROLE_NAME) || false;
        const isCurrentUserAdmin = roles?.includes(ADMIN_ROLE_NAME) || false;
        setIsAdmin(isCurrentUserAdmin);
        setUnauthorized(!isCurrentUserMember && !isCurrentUserAdmin);
        setToken(token);
      } catch (error) {
        console.log('Error retrieving user data: ', error);
      }
    }
  }, [account]);

  useEffect(() => {
    if (token) {
      usersService()
        .getCurrentUser()
        .then((response) => {
          if (response) {
            setUserData(response);
            setHasCTWAccess(Boolean(response.octwaccess));
          } else {
            setUserData('NO_USER_FOUND');
            setUnauthorized(true);
          }
        })
        .catch((e) => {
          setUserData('NO_USER_FOUND');
          setUnauthorized(true);
        });
    }
  }, [token]);

  return { user, token, unauthorized, isAdmin, logout, switchAccount, userData, hasCTWAccess };
};

export default useAuth;
