import { McSegmentedControl, McSegmentedControlItem, McTable } from '@maersk-global/mds-react-wrapper';
import React, { useState } from 'react';
import useCapacityData from '../../hooks/useCapacityData';
import EditOccValuesDrawer from '../EditOccValuesDrawer/EditOccValuesDrawer';
import { mapCapacityType } from '../utils/capacityTypeMapper';

type OCCTabProps = {
  selectedTerminal: string;
};

const OCCTab: React.FC<OCCTabProps> = (props) => {
  const { selectedTerminal } = props;
  const [occDateType, setOccDateType] = useState('HOURS_30_36');
  const [selectedOccRow, setSelectedOccRow] = useState<any>(null);
  const { occ } = useCapacityData();

  return (
    <>
      <div>
        <h2>Overlapping Connections Capacity</h2>
        <McSegmentedControl listchange={(e) => setOccDateType((e.target as HTMLInputElement).value)}>
          <McSegmentedControlItem selected={occDateType === 'HOURS_30_36'} value="HOURS_30_36">
            30 - 36 h
          </McSegmentedControlItem>
          <McSegmentedControlItem value="HOURS_36_42">36 - 42 h</McSegmentedControlItem>
          <McSegmentedControlItem value="HOURS_42_48">42 - 48 h</McSegmentedControlItem>
          <McSegmentedControlItem value="HOURS_48_PLUS">{'> 48 h'}</McSegmentedControlItem>
        </McSegmentedControl>
        <div style={{ marginTop: '20px', width: 'max-content' }}>
          <McTable
            columns={[
              { id: 'from', label: 'From (PC)', align: 'left' },
              { id: 'to', label: 'To (OC)', align: 'left' },
              { id: 'vesselPairLimits', label: 'Vessel pair limits', align: 'left' },
            ]}
            data={
              occ &&
              occ[occDateType] &&
              occ[occDateType].capacityEntries.map((item, index) => ({
                id: index,
                from: mapCapacityType(item.from),
                to: mapCapacityType(item.to),
                vesselPairLimits: `${item.vesselPairLimits}`,
                dateType: occDateType,
              }))
            }
            rowclick={(e) => {
              const row = e.detail.rowData;
              setSelectedOccRow(row);
            }}
          />
        </div>
      </div>

      <EditOccValuesDrawer
        occValues={selectedOccRow}
        selectedTerminal={selectedTerminal}
        open={Boolean(selectedOccRow)}
        setOpen={() => setSelectedOccRow(null)}
      />
    </>
  );
};

export default OCCTab;
