import { McButton, McModal } from '@maersk-global/mds-react-wrapper';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const ResetCarrierModal = ({ closed, onConfirm }: { closed: () => void; onConfirm: () => void }) => {
  const { formatMessage } = useIntl();

  return (
    <McModal
      open
      closed={closed}
      dimension={'small'}
      heading={formatMessage({ id: 'resetCarrierCapacityModalHeading' })}
    >
      <FormattedMessage id="resetCarrierCapacityModalPrompt" />
      <McButton
        slot="secondaryAction"
        appearance="neutral"
        variant="outlined"
        dialogaction="cancel"
        label={formatMessage({ id: 'cancel' })}
      />
      <McButton
        id="confirm"
        slot="primaryAction"
        appearance="primary"
        click={onConfirm}
        label={formatMessage({ id: 'reset' })}
      />
    </McModal>
  );
};
