import React, { FunctionComponent, useEffect, useState } from 'react';
import { DependencyContainer } from '../../../http/DependencyContainer';
import { useRecoilState } from 'recoil';
import { octwRulesAtom } from '../../../state';
import FormattedMessage from '../../common/FormattedMessage';
import OctwAdminView from '../components/OctwAdminView';

const { octwService } = new DependencyContainer();

const RulesContainer: FunctionComponent = () => {
  const [, setRules] = useRecoilState(octwRulesAtom);
  const [, setError] = useState();
  const [, setLoading] = useState(false);

  const fetchRules = () => {
    setLoading(true);
    octwService
      .getRules()
      .then((rules) => {
        setRules(rules);
        setError(undefined);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- by design
  }, []);

  return (
    <div className="RulesContainer">
      <h2 className="container-title">
        <FormattedMessage id="rules" />
      </h2>
      <div className="RulesContainer__cards">
        <OctwAdminView fetchRules={fetchRules} />
      </div>
    </div>
  );
};

export default RulesContainer;
