import { httpClient } from '../http-client';
import { User } from './types';

export const usersService = () => {
  const client = httpClient();
  const BASE_URL = '/v1/user';

  return {
    fetchUsers: async ({ terminal }: { terminal: string }): Promise<User[] | null> => {
      const url = `${BASE_URL}/terminal/${terminal}`;
      try {
        const result = await client.get<User[]>(url);
        return result.data;
      } catch (e) {
        logger(`Error calling API: ${e}`);
        return null;
      }
    },
    fetchGlobalUsers: async (): Promise<User[] | null> => {
      const url = `${BASE_URL}/octw`;
      try {
        const result = await client.get<User[]>(url);
        return result.data;
      } catch (e) {
        logger(`Error calling API: ${e}`);
        return null;
      }
    },
    createUser: async ({ terminal, email }: { terminal: string; email: string }): Promise<User | null> => {
      const url = `${BASE_URL}`;
      try {
        const result = await client.post<User>(url, { terminalCode: terminal, email });
        return result.data;
      } catch (e) {
        logger(`Error calling API: ${e}`);
        return null;
      }
    },
    createGlobalUser: async ({ email }: { email: string }): Promise<User | null> => {
      const url = `${BASE_URL}/octw/grant`;
      try {
        const result = await client.put<User>(url, { email });
        return result.data;
      } catch (e) {
        logger(`Error calling API: ${e}`);
        return null;
      }
    },
    deleteUser: async ({ terminal, id }: { terminal: string; id: string }): Promise<User | null> => {
      const url = `${BASE_URL}/delete`;
      try {
        const result = await client.post<User>(url, { terminalCode: terminal, id });
        return result.data;
      } catch (e) {
        logger(`Error calling API: ${e}`);
        return null;
      }
    },
    deleteGlobalUser: async ({ id }: { id: string }): Promise<User | null> => {
      const url = `${BASE_URL}/octw/revoke`;
      try {
        const result = await client.put<User>(url, { userId: id });
        return result.data;
      } catch (e) {
        logger(`Error calling API: ${e}`);
        return null;
      }
    },
    getCurrentUser: async (): Promise<User | null> => {
      const url = `${BASE_URL}`;
      try {
        const result = await client.get<User>(url);
        return result.data;
      } catch (e) {
        logger(`Error calling API: ${e}`);
        return null;
      }
    },
  };
};

const logger = (message: string): void => console.error(`[Users Service] ${message}`);
