import OctwClient from './clients/OctwClient';
import OctwService from './services/OctwService';
import UsersService from './services/UsersService';
import UsersClient from './clients/UsersClient';
import CapacityService from './services/CapacityService';
import CapacityClient from './clients/CapacityClient';

export class DependencyContainer {
  // services
  octwService = new OctwService(this);
  usersService = new UsersService(this);
  capacityService = new CapacityService(this);

  //clients
  octwClient = new OctwClient(this);
  usersClient = new UsersClient(this);
  capacityClient = new CapacityClient(this);
}
