import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { McButton, McModal } from '@maersk-global/mds-react-wrapper';
import z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import useUsers from '../../capacity/hooks/useUsers';
import { AdjustableComponent, useClasses } from '../../common/hooks/useClasses';
import { FormattedMessage } from 'react-intl';
import FormikInputField from '../../common/components/FormikInputField/FormikInputField';
import Drawer from '../../common/components/Drawer/Drawer';

export type AddDefaultValuesDrawerStyles = {
  field: string;
  buttons: string;
  form: string;
};

export type AddDefaultValuesDrawerProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onSuccess?: Function;
  selectedTerminal: string;
  selectedUser: any;
  isGlobal: boolean;
};

export type EditRuleFormShape = {
  terminalId: string;
  id: string;
  email: string;
};

const FormValidationSchema = z.object({
  terminalId: z.string(),
  email: z.string(),
  id: z.string(),
});

const EditUserDrawer: AdjustableComponent<AddDefaultValuesDrawerProps, AddDefaultValuesDrawerStyles> = ({
  classes,
  open,
  setOpen,
  onSuccess,
  selectedTerminal,
  selectedUser,
  isGlobal,
}) => {
  const styles = useClasses(
    {
      field: 'AddDefaultValuesDrawer__field',
      buttons: 'AddDefaultValuesDrawer__buttons',
      form: 'AddDefaultValuesDrawer__form-wrapper',
    },
    classes,
  );

  const deleteModalRef = useRef(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { deleteUser, deleteGlobalUser } = useUsers();

  const onClose = () => {
    setOpen(false);
  };
  const getFormInitialValues = (): EditRuleFormShape => {
    return {
      terminalId: selectedTerminal,
      id: selectedUser.id,
      email: selectedUser.email,
    };
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      // TBD
      onClose();
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Drawer title={'Delete user'} open={open} setOpen={setOpen} onRequestClose={onClose} noFooter>
      <Formik
        initialValues={getFormInitialValues()}
        enableReinitialize
        validationSchema={toFormikValidationSchema(FormValidationSchema)}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form className={styles.form}>
            <div>
              <Field label={'Email'} type="text" name="email" disabled component={FormikInputField} />
              <ErrorMessage name="email" component="div" />
              <Field type="hidden" name="terminalId" />
            </div>

            <div className={styles.buttons}>
              <McButton type="button" appearance="neutral" click={onClose}>
                <FormattedMessage id="cancel" />
              </McButton>
              {/*<McButton disabled={true} type="submit">*/}
              {/*  <FormattedMessage id={isSubmitting ? 'updateRuleDrawerFormSubmitting' : 'updateRuleDrawerFormSubmit'} />*/}
              {/*</McButton>*/}

              <McButton
                type="button"
                appearance="error"
                click={() => {
                  if (deleteModalRef.current) {
                    // @ts-ignore
                    deleteModalRef.current.show();
                  }
                }}
              >
                Delete
              </McButton>
            </div>
          </Form>
        )}
      </Formik>
      <McModal ref={deleteModalRef} dimension={'small'} heading={'Revoke Access'}>
        <p id={'question'}>Are you sure you want to revoke access for this user?</p>
        <McButton slot="secondaryAction" appearance="neutral" variant="outlined" dialogaction="cancel" label="Cancel" />
        <McButton
          id="confirm"
          slot="primaryAction"
          appearance="primary"
          loading={deleteLoading}
          click={async () => {
            setDeleteLoading(true);
            if (isGlobal) {
              await deleteGlobalUser({ id: selectedUser.id });
            } else {
              await deleteUser({ terminal: selectedTerminal, id: selectedUser.id });
            }
            onSuccess?.();
            onClose();
            setDeleteLoading(false);
            // @ts-ignore
            deleteModalRef.current.close();
          }}
          label="Delete"
        />
      </McModal>
    </Drawer>
  );
};

export default EditUserDrawer;
