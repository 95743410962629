import React, { FunctionComponent } from 'react';

export const ChevronDown: FunctionComponent<JSX.IntrinsicElements['svg']> = (props) => {
  return (
    <svg width="8" height="6" viewBox="0 0 8 6" fill="none" {...props}>
      <path
        d="M7.06 0.726563L4 3.7799L0.94 0.726563L8.21774e-08 1.66656L4 5.66656L8 1.66656L7.06 0.726563Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const ColumnFilterIcon: FunctionComponent<JSX.IntrinsicElements['svg']> = (props) => {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.6667 11.1429C10.6667 10.9155 10.5964 10.6975 10.4714 10.5368C10.3464 10.376 10.1768 10.2857 10 10.2857H6C5.82319 10.2857 5.65362 10.376 5.52859 10.5368C5.40357 10.6975 5.33333 10.9155 5.33333 11.1429C5.33333 11.3702 5.40357 11.5882 5.52859 11.7489C5.65362 11.9097 5.82319 12 6 12H10C10.1768 12 10.3464 11.9097 10.4714 11.7489C10.5964 11.5882 10.6667 11.3702 10.6667 11.1429ZM13.3333 6C13.3333 5.77267 13.2631 5.55465 13.1381 5.39391C13.013 5.23316 12.8435 5.14286 12.6667 5.14286H3.33333C3.15652 5.14286 2.98695 5.23316 2.86193 5.39391C2.7369 5.55465 2.66667 5.77267 2.66667 6C2.66667 6.22733 2.7369 6.44535 2.86193 6.60609C2.98695 6.76684 3.15652 6.85714 3.33333 6.85714H12.6667C12.8435 6.85714 13.013 6.76684 13.1381 6.60609C13.2631 6.44535 13.3333 6.22733 13.3333 6ZM16 0.857143C16 0.629814 15.9298 0.411797 15.8047 0.251051C15.6797 0.090306 15.5101 0 15.3333 0H0.666666C0.489855 0 0.320285 0.090306 0.195261 0.251051C0.0702372 0.411797 0 0.629814 0 0.857143C0 1.08447 0.0702372 1.30249 0.195261 1.46323C0.320285 1.62398 0.489855 1.71429 0.666666 1.71429H15.3333C15.5101 1.71429 15.6797 1.62398 15.8047 1.46323C15.9298 1.30249 16 1.08447 16 0.857143Z"
        fill="#3C4546"
      />
      <path
        d="M10.6667 11.1429C10.6667 10.9155 10.5964 10.6975 10.4714 10.5368C10.3464 10.376 10.1768 10.2857 10 10.2857H6C5.82319 10.2857 5.65362 10.376 5.52859 10.5368C5.40357 10.6975 5.33333 10.9155 5.33333 11.1429C5.33333 11.3702 5.40357 11.5882 5.52859 11.7489C5.65362 11.9097 5.82319 12 6 12H10C10.1768 12 10.3464 11.9097 10.4714 11.7489C10.5964 11.5882 10.6667 11.3702 10.6667 11.1429ZM13.3333 6C13.3333 5.77267 13.2631 5.55465 13.1381 5.39391C13.013 5.23316 12.8435 5.14286 12.6667 5.14286H3.33333C3.15652 5.14286 2.98695 5.23316 2.86193 5.39391C2.7369 5.55465 2.66667 5.77267 2.66667 6C2.66667 6.22733 2.7369 6.44535 2.86193 6.60609C2.98695 6.76684 3.15652 6.85714 3.33333 6.85714H12.6667C12.8435 6.85714 13.013 6.76684 13.1381 6.60609C13.2631 6.44535 13.3333 6.22733 13.3333 6ZM16 0.857143C16 0.629814 15.9298 0.411797 15.8047 0.251051C15.6797 0.090306 15.5101 0 15.3333 0H0.666666C0.489855 0 0.320285 0.090306 0.195261 0.251051C0.0702372 0.411797 0 0.629814 0 0.857143C0 1.08447 0.0702372 1.30249 0.195261 1.46323C0.320285 1.62398 0.489855 1.71429 0.666666 1.71429H15.3333C15.5101 1.71429 15.6797 1.62398 15.8047 1.46323C15.9298 1.30249 16 1.08447 16 0.857143Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
    </svg>
  );
};

export const InfoIcon: FunctionComponent<JSX.IntrinsicElements['svg']> = (props) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
        stroke="#3C4546"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99999 12.2003V9.00033"
        stroke="#3C4546"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99999 12.2003V9.00033"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99999 5.80013H9.00799"
        stroke="#3C4546"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99999 5.80013H9.00799"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const GroupByRowIcon: FunctionComponent<JSX.IntrinsicElements['svg']> = (props) => {
  return (
    <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.11914 4.35431L1.23029 1.66633C1.07829 1.52406 0.992982 1.33162 0.992982 1.13101C0.992982 0.930415 1.07829 0.737965 1.23029 0.595698C1.30615 0.524529 1.39641 0.46804 1.49585 0.42949C1.5953 0.390941 1.70196 0.371094 1.80969 0.371094C1.91742 0.371094 2.02408 0.390941 2.12353 0.42949C2.22297 0.46804 2.31323 0.524529 2.38909 0.595698L5.84919 3.81519C5.92568 3.88578 5.98639 3.96976 6.02782 4.06229C6.06925 4.15482 6.09058 4.25407 6.09058 4.35431C6.09058 4.45454 6.06925 4.55379 6.02782 4.64632C5.98639 4.73885 5.92568 4.82283 5.84919 4.89342L2.38909 8.15088C2.31284 8.22125 2.22241 8.27693 2.12298 8.31472C2.02355 8.3525 1.91709 8.37166 1.80969 8.37108C1.70229 8.37166 1.59583 8.3525 1.4964 8.31472C1.39697 8.27693 1.30654 8.22125 1.23029 8.15088C1.07829 8.00861 0.992983 7.81616 0.992983 7.61556C0.992983 7.41496 1.07829 7.22251 1.23029 7.08025L4.11914 4.35431Z"
        fill="#3C4546"
      />
      <path
        d="M4.11914 4.35431L1.23029 1.66633C1.07829 1.52406 0.992982 1.33162 0.992982 1.13101C0.992982 0.930415 1.07829 0.737965 1.23029 0.595698C1.30615 0.524529 1.39641 0.46804 1.49585 0.42949C1.5953 0.390941 1.70196 0.371094 1.80969 0.371094C1.91742 0.371094 2.02408 0.390941 2.12353 0.42949C2.22297 0.46804 2.31323 0.524529 2.38909 0.595698L5.84919 3.81519C5.92568 3.88578 5.98639 3.96976 6.02782 4.06229C6.06925 4.15482 6.09058 4.25407 6.09058 4.35431C6.09058 4.45454 6.06925 4.55379 6.02782 4.64632C5.98639 4.73885 5.92568 4.82283 5.84919 4.89342L2.38909 8.15088C2.31284 8.22125 2.22241 8.27693 2.12298 8.31472C2.02355 8.3525 1.91709 8.37166 1.80969 8.37108C1.70229 8.37166 1.59583 8.3525 1.4964 8.31472C1.39697 8.27693 1.30654 8.22125 1.23029 8.15088C1.07829 8.00861 0.992983 7.81616 0.992983 7.61556C0.992983 7.41496 1.07829 7.22251 1.23029 7.08025L4.11914 4.35431Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
    </svg>
  );
};

export const ScenarioOutputTableFooterIcon: FunctionComponent<JSX.IntrinsicElements['svg']> = (props) => {
  return (
    <svg width="16" height="5" viewBox="0 0 16 5" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0.5 4.5H15.5" stroke="white" strokeLinecap="round" />
      <path d="M0.5 0.5H15.5" stroke="white" strokeLinecap="round" />
    </svg>
  );
};

export const MessageBubble: FunctionComponent<JSX.IntrinsicElements['svg']> = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2 4C2 3.44771 2.44772 3 3 3H17C17.5523 3 18 3.44771 18 4V14C18 14.5523 17.5523 15 17 15H6.26759L4.56446 17.5547C4.37899 17.8329 4.06676 18 3.73241 18H3C2.44772 18 2 17.5523 2 17V4ZM17 4L3 4V17H3.73241L5.43554 14.4453C5.62101 14.1671 5.93324 14 6.26759 14H17V4ZM15 8H5V7H15V8ZM12 11H5V10H12V11Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Back: FunctionComponent<JSX.IntrinsicElements['svg']> = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.34921 8.4707L8.6746 5.62036L9.3254 6.37962L6 9.22996V9.49999H15V10.5H6V10.77L9.3254 13.6204L8.6746 14.3796L5.34921 11.5293C5.12756 11.3393 5 11.0619 5 10.77V9.22996C5 8.93803 5.12756 8.66068 5.34921 8.4707Z"
        fill="currentColor"
      />
    </svg>
  );
};
