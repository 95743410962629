export const formattedNumber = (number?: number): string => {
  if (number === null) {
    return '';
  }
  return `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const formattedNumberToInt = (string?: string): number => {
  if (!string) {
    return 0;
  }
  if (typeof string === 'number') {
    return parseInt(string);
  }
  return parseInt(string.replaceAll(' ', ''), 10);
};
