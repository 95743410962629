import React from 'react';
import useAuth from './hooks/useAuth';
import { FunctionComponentWithChildren } from './types';
import { Navigate, useLocation } from 'react-router-dom';
import { AppRoutes } from '../../config/routes';

const ForAuthenticated: FunctionComponentWithChildren = ({ children }) => {
  const { user, userData, unauthorized, switchAccount, isAdmin, hasCTWAccess } = useAuth();
  const location = useLocation();

  if (!user || !userData) {
    return <div>Loading APMT Port Data...</div>;
  }
  if (user && unauthorized) {
    return (
      <div>
        Unauthorized, please use a different account or request access.
        <br />
        <button onClick={switchAccount}>Switch Account</button>
      </div>
    );
  }
  if (!(isAdmin || hasCTWAccess) && location.pathname === '/') {
    return <Navigate to={AppRoutes.CapacityId.replace(':id', userData.terminalCodes[0])} replace />;
  }

  return <>{children}</>;
};

export default ForAuthenticated;
