import { AppRoutes } from '../../../config/routes';
import { NavigateOptions, useNavigate } from 'react-router-dom';

export const useRedirectToRoute = () => {
  const navigate = useNavigate();

  return (routeName: AppRoutes, params?: any, options?: NavigateOptions, newWindow = false) => {
    const allParams = Object.keys(params || {});
    const route =
      params !== undefined
        ? allParams.reduce((acc: string, param: string) => acc.replace(`:${param}`, params[param]), routeName)
        : routeName;
    if (newWindow) {
      window.open(`${route}`);
      return;
    }
    navigate(route, options);
  };
};
