import { FunctionComponentWithChildren } from '../types';

export type AdjustableComponent<P, S> = FunctionComponentWithChildren<P & { classes?: ClassesModifier<S> }>;

export type AdjustableComponentType<P, S> = P & {
  classes?: ClassesModifier<S>;
};

export type ClassesModifier<T> = (styles: T) => T;

export const useClasses = <T extends { [key: string]: string }>(defaultStyles: T, classes?: ClassesModifier<T>) => {
  return classes ? classes(defaultStyles) : defaultStyles;
};

export type WithAdjustableComponent<P, S> = P & {
  classes?: ClassesModifier<S>;
};
