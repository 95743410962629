import { formattedNumber } from '../../common/helpers/utils';
import { CapacityDataType } from '../types';
import { modifyDate } from '../../common/helpers/modify-date';
import { terminals } from '../../common/helpers/terminals';

export const weeklyDataMapperSOC = (template: any) => {
  const entry = template.entries.find((e) => e.capacityDataType === CapacityDataType.Total);
  return {
    id: template.id,
    date: `${template.date}`,
    moveCount: formattedNumber(entry.moves),
    moveCountStretchPercentage: entry.moveCountStretchPercentage,
    moveCountStretch: getAdjustedMoveCount(entry.moves, entry.moveCountStretchPercentage),
    moveCountCritical: getAdjustedMoveCount(entry.moves, entry.moveCountCriticalPercentage),
    moveCountCriticalPercentage: entry.moveCountCriticalPercentage,
    capacityDataType: entry.capacityDataType,
    overwrite: entry.overwrite,
  };
};

export const weeklyDataMapperYard = (template: any) => {
  const entry = template.entries.find((e) => e.capacityDataType === CapacityDataType.Total);
  return {
    id: template.id,
    date: `${template.date}`,
    yardCapacity: formattedNumber(entry.yardCapacity),
    reeferPlugs: formattedNumber(entry.reeferPlugs),
    imoUnits: formattedNumber(entry.imoUnits),
    imoUnitsDescription: entry.imoUnitsDescription,
    oogUnits: formattedNumber(entry.oogUnits),
    capacityDataType: entry.capacityDataType,
    overwrite: entry.overwrite,
  };
};

// @TODO: Replace types!
export const defaultDataMapperSOC = (capacityTemplateEntities: any) => (template: any, index: number) => {
  const entry = template.entries.find((e) => e.capacityDataType === CapacityDataType.Total);
  const previousStartDate = capacityTemplateEntities[index - 1]?.startDate?.split('T')[0];
  const startDate = template.startDate.split('T')[0];
  const nextStartDate = capacityTemplateEntities[index + 1]?.startDate?.split('T')[0];

  return {
    id: template.id,
    startDate: template.startDate,
    previousStartDate: previousStartDate,
    nextStartDate: nextStartDate,
    validityPeriod:
      startDate.replaceAll('-', '/') +
      ' - ' +
      (nextStartDate ? modifyDate(nextStartDate, 'remove').replaceAll('-', '/') : '2100/01/01'),
    moveCount: formattedNumber(entry.moves),
    moves: formattedNumber(entry.moves),
    moveCountStretchPercentage: entry.moveCountStretchPercentage,
    moveCountCriticalPercentage: entry.moveCountCriticalPercentage,
    moveCountStretch: getAdjustedMoveCount(entry.moves, entry.moveCountStretchPercentage),
    moveCountCritical: getAdjustedMoveCount(entry.moves, entry.moveCountCriticalPercentage),
    entries: fillCarriers(
      template.terminalCode,
      template.entries.filter((e) => e.capacityDataType !== CapacityDataType.Total),
    ),
    overwrite: entry.overwrite,
    status: entry.overwrite ? 'Adjusted' : 'Default',
  };
};

export const defaultDataMapperYard = (capacityTemplateEntities: any) => (template: any, index: number) => {
  const entry = template.entries.find((e) => e.capacityDataType === CapacityDataType.Total);
  const previousStartDate = capacityTemplateEntities[index - 1]?.startDate?.split('T')[0];
  const startDate = template.startDate.split('T')[0];
  const nextStartDate = capacityTemplateEntities[index + 1]?.startDate?.split('T')[0];

  return {
    id: template.id,
    startDate: template.startDate,
    previousStartDate: previousStartDate,
    nextStartDate: nextStartDate,
    validityPeriod:
      startDate.replaceAll('-', '/') +
      ' - ' +
      (nextStartDate ? modifyDate(nextStartDate, 'remove').replaceAll('-', '/') : '2100/01/01'),
    yardCapacity: formattedNumber(entry.yardCapacity),
    reeferPlugs: formattedNumber(entry.reeferPlugs),
    imoUnits: formattedNumber(entry.imoUnits),
    imoUnitsDescription: entry.imoUnitsDescription,
    oogUnits: formattedNumber(entry.oogUnits),
    entries: fillCarriers(
      template.terminalCode,
      template.entries.filter((e) => e.capacityDataType !== CapacityDataType.Total),
    ),
    overwrite: entry.overwrite,
    status: entry.overwrite ? 'Adjusted' : 'Default',
  };
};

export const fillCarriers = (terminalCode, entries) => {
  const terminal = terminals.find((terminal) => terminal.code === terminalCode);
  // @ts-ignore
  const existingCarriers = entries
    .filter((e) => e.capacityDataType !== CapacityDataType.Total)
    .map((e) => e.capacityDataType);
  // @ts-ignore
  const missingCarriers = terminal.carriers.filter((e) => !existingCarriers.includes(e));

  return [
    ...entries,
    ...missingCarriers.map((carrier) => ({
      capacityDataType: carrier,
      moves: 0,
      yardCapacity: 0,
      reeferPlugs: 0,
      moveCountStretchPercentage: 0,
      moveCountStretchNumber: 0,
      moveCountCriticalPercentage: 0,
      moveCountCriticalNumber: 0,
      imoUnits: 0,
      imoUnitsDescription: null,
      oogUnits: 0,
      overwrite: 0,
    })),
  ].map((e) => ({
    ...e,
    moveCount: e.moves,
  }));
};

const getAdjustedMoveCount = (moves: number, percentage: number): string => {
  return `${formattedNumber(moves + Math.ceil((moves * percentage) / 100))} (${percentage || 0}%)`;
};
