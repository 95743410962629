import { TableColumn } from '@maersk-global/mds-components-core/mc-table/types';

export const defaultColumns: TableColumn[] = [
  { id: 'carrier', label: '', align: 'left', sortDisabled: true, width: '120px' },
  { id: 'moveCount', label: 'Move count', align: 'left' },
  { id: 'moveCountStretch', label: 'Threshold L2', align: 'left' },
  { id: 'moveCountCritical', label: 'Threshold L3', align: 'left' },
  { id: 'yardCapacity', label: 'Yard Capacity', align: 'left' },
  { id: 'reeferPlugs', label: 'Reefer plugs', align: 'left' },
  { id: 'imoUnits', label: 'IMO units', align: 'left' },
  { id: 'oogUnits', label: 'OOG units', align: 'left' },
  { id: 'validityPeriod', label: 'Validity period', align: 'left' },
  { id: 'actions', label: 'Actions', align: 'right' },
];

export const weeklyColumns: TableColumn[] = [
  { id: 'carrier', label: '', align: 'left', sortDisabled: true, width: '120px' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'moveCount', label: 'Move count', align: 'left' },
  { id: 'moveCountStretch', label: 'Threshold L2', align: 'left' },
  { id: 'moveCountCritical', label: 'Threshold L3', align: 'left' },
  { id: 'yardCapacity', label: 'Yard Capacity', align: 'left' },
  { id: 'reeferPlugs', label: 'Reefer plugs', align: 'left' },
  { id: 'imoUnits', label: 'IMO units', align: 'left' },
  { id: 'oogUnits', label: 'OOG units', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'actions', label: 'Actions', align: 'right', width: '200px' },
];
