export function pushOrUpdateCollection<T extends any>(collection: T[], item: T, primaryKey: keyof T) {
  if (!primaryKey) {
    throw new Error('primary key is missing');
  }
  const output = [...collection];
  const index = collection.findIndex((c) => c[primaryKey] === item[primaryKey]);
  if (index !== -1) {
    output[index] = item;
  } else {
    output.push(item);
  }
  return output;
}
