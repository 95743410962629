import { TableColumn } from '@maersk-global/mds-components-core/mc-table/types';

export const columns: Array<TableColumn> = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'email',
    label: 'Email',
    tabularFigures: true,
  },
  {
    id: 'action',
    label: 'Action',
    sortDisabled: true,
    align: 'left',
  },
];
