import { atom, selector } from 'recoil';
import { User } from './features/common/service/users/types';
import { OctwRule } from './http/clients/OctwClient';
import { Maybe } from './features/common/types';
import { CapacityDataType, CapacityEntity, OccResponse } from './features/capacity/types';
import { ColumnsDefinitionType } from './features/capacity/hooks/useCarrierOperations';

export const octwRulesAtom = atom<OctwRule[]>({
  key: 'octwRulesAtom',
  default: [],
});

export const usersAtom = atom<User[]>({
  key: 'usersAtom',
  default: [],
});

export const userDropdownOpenAtom = atom<boolean>({
  key: 'userDropdownOpenAtom',
  default: false,
});

export const selectedRuleIdForEditAtom = atom<Maybe<string>>({
  key: 'selectedRuleIdForEdit',
  default: undefined,
});

export const selectedRuleForEditSelector = selector<Maybe<OctwRule>>({
  key: 'selectedRuleForEditSelector',
  get: ({ get }) => {
    const selectedRuleId = get(selectedRuleIdForEditAtom);
    if (!selectedRuleId) {
      return undefined;
    }
    const rules = get(octwRulesAtom);

    return rules.find((rule) => rule.hubId === selectedRuleId);
  },
});

export const terminalCodeAtom = atom<string>({
  key: 'terminalCodeAtom',
  default: 'EGPSD',
});

export const terminalCodeUsersAtom = atom<string>({
  key: 'terminalCodeUsersAtom',
  default: '',
});

export const capacityEntitiesAtomSOC = atom<CapacityEntity[]>({
  key: 'capacityEntitiesAtomSOC',
  default: [],
});

export const capacityTemplatesAtomSOC = atom<CapacityEntity[]>({
  key: 'capacityTemplatesAtomSOC',
  default: [],
});

export const capacityEntitiesAtomYard = atom<CapacityEntity[]>({
  key: 'capacityEntitiesAtomYard',
  default: [],
});

export const capacityTemplatesAtomYard = atom<CapacityEntity[]>({
  key: 'capacityTemplatesAtomYard',
  default: [],
});

export const occAtom = atom<OccResponse>({
  key: 'occAtom',
  default: undefined,
});

// @TODO: Change it
export type CarrierUpdateDto = any;

type CarriersUnderEdit = {
  carrierId: CapacityDataType;
  entryId: string;
  type: ColumnsDefinitionType;
  dto: CarrierUpdateDto;
};

export const carriersUnderEditAtom = atom<CarriersUnderEdit[]>({
  key: 'carriersUnderEdit',
  default: [],
});
