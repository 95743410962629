import { FieldInputProps, FormikProps } from 'formik';
import { FunctionComponent } from 'react';
import { McInput } from '@maersk-global/mds-react-wrapper';

export type FormikInputFieldProps = {
  field: FieldInputProps<string>;
  form: FormikProps<string>;
  label: string;
  error?: string;
  width?: number;
} & JSX.IntrinsicElements['input'];

const FormikInputField: FunctionComponent<FormikInputFieldProps> = ({ field, form, error, ...inputProps }) => {
  const onInput = async (e: InputEvent) => {
    const value =
      inputProps.type === 'number'
        ? // @ts-ignore
          parseFloat(e.target.value)
        : // @ts-ignore
          e.target.value;
    await form.setFieldValue(field.name, value);
  };

  return (
    // @ts-ignore
    <McInput disabled={inputProps.disabled} name={field.name} value={field.value} input={onInput} {...inputProps} />
  );
};

export default FormikInputField;
