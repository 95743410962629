import { enqueueSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { usersAtom, terminalCodeUsersAtom } from '../../../state';
import { usersService } from '../../common/service/users/users-service';
import { useEffect, useState } from 'react';

const useUsers = () => {
  const { id } = useParams<{ id: string }>();
  const [terminalCode, setTerminalCode] = useRecoilState(terminalCodeUsersAtom);
  const [users, setUsers] = useRecoilState(usersAtom);
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (id && id !== terminalCode) {
      setTerminalCode(id);
    }
  }, [id]);

  const fetchUsers = (terminal: string) => {
    setLoading(true);
    return usersService()
      .fetchUsers({ terminal })
      .then((result) => {
        if (result) {
          setUsers(result);
        } else {
          enqueueSnackbar(formatMessage({ id: 'genericErrorMessage' }), { variant: 'error' });
        }
        setLoading(false);
      });
  };

  const fetchGlobalUsers = () => {
    setLoading(true);
    return usersService()
      .fetchGlobalUsers()
      .then((result) => {
        if (result) {
          setUsers(result);
        } else {
          enqueueSnackbar(formatMessage({ id: 'genericErrorMessage' }), { variant: 'error' });
        }
        setLoading(false);
      });
  };

  const addUser = (data: { terminal: string; email: string }) => {
    return usersService()
      .createUser(data)
      .then((result) => {
        if (result) {
          enqueueSnackbar(`User created successfully: ${data.email}`, { variant: 'success' });
          fetchUsers(terminalCode);
        } else {
          enqueueSnackbar(`User not found for email: ${data.email}`, { variant: 'error' });
        }
      });
  };

  const addGlobalUser = (data: { email: string }) => {
    return usersService()
      .createGlobalUser(data)
      .then((result) => {
        if (result) {
          enqueueSnackbar(`User created successfully: ${data.email}`, { variant: 'success' });
          fetchGlobalUsers();
        } else {
          enqueueSnackbar(`User not found for email: ${data.email}`, { variant: 'error' });
        }
      });
  };

  const deleteUser = (data: { terminal: string; id: string }) => {
    return usersService()
      .deleteUser(data)
      .then((result) => {
        if (result !== null) {
          enqueueSnackbar(`User access revoked from: ${data.terminal}`, { variant: 'success' });
          fetchUsers(terminalCode);
        } else {
          enqueueSnackbar(formatMessage({ id: 'genericErrorMessage' }), { variant: 'error' });
        }
      });
  };

  const deleteGlobalUser = (data: { id: string }) => {
    return usersService()
      .deleteGlobalUser(data)
      .then((result) => {
        if (result !== null) {
          enqueueSnackbar('User access revoked from', { variant: 'success' });
          fetchGlobalUsers();
        } else {
          enqueueSnackbar(formatMessage({ id: 'genericErrorMessage' }), { variant: 'error' });
        }
      });
  };

  return {
    terminalCode,
    loading,
    users,
    fetchUsers,
    fetchGlobalUsers,
    setTerminalCode,
    addUser,
    deleteUser,
    addGlobalUser,
    deleteGlobalUser,
  };
};

export default useUsers;
