import React, { ReactNode, Dispatch, SetStateAction } from 'react';
import { McButton } from '@maersk-global/mds-react-wrapper';
import cx from 'classnames';
import { useClickOutside } from '../../hooks/useClickOutside';
import { AdjustableComponent, useClasses } from '../../hooks/useClasses';

export type DrawerStyles = {
  root: string;
  wrapper: string;
  title: string;
  content: string;
  footer: string;
};

export type DrawerProps = {
  title: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  noFooter?: boolean;
  noTitle?: boolean;
  onRequestClose?: () => void;
  submitButton?: ReactNode;
  titleAddon?: ReactNode;
};

const Drawer: AdjustableComponent<DrawerProps, DrawerStyles> = ({
  classes,
  title,
  open,
  setOpen,
  onRequestClose,
  noFooter = false,
  noTitle = false,
  submitButton,
  children,
  titleAddon,
}) => {
  const styles = useClasses(
    {
      root: cx('Drawer', {
        'Drawer--open': open,
      }),
      wrapper: 'Drawer__wrapper',
      title: 'Drawer__title',
      content: 'Drawer__content',
      footer: 'Drawer__footer',
    },
    classes,
  );

  const { ref } = useClickOutside({
    toggler: () => setOpen((p) => !p),
    state: open,
  });

  const onModalClose = () => {
    setOpen(false);
    onRequestClose?.();
  };

  return (
    <div className={styles.root} ref={ref}>
      <div className={styles.wrapper}>
        {!noTitle && <div className={styles.title}><h3>{title}</h3>{titleAddon && titleAddon}</div>}
        <div className={styles.content}>{children}</div>
        {!noFooter && (
          <div className={styles.footer}>
            <McButton
              type="button"
              variant="outlined"
              label="Close"
              onClick={() => onModalClose()}
              appearance="neutral"
              dialogaction="cancel"
              fit="medium"
            />
            {submitButton}
          </div>
        )}
      </div>
    </div>
  );
};

export default Drawer;
