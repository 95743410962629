import React, { FunctionComponent } from 'react';
import { Changelog } from '../../../types';
import Step from './step';
import './steps.scss';

export type StepsProps = {
  changelog: Changelog[];
};

const Steps: FunctionComponent<StepsProps> = ({ changelog }) => {
  return (
    <div className="ChangelogSteps">
      <div className="ChangelogSteps__wrapper">
        {changelog.map((entry) => {
          return <Step key={entry.id} entry={entry} />;
        })}
      </div>
    </div>
  );
};

export default Steps;
