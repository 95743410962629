import React, { FunctionComponent, useEffect, useState } from 'react';
import { McButton, McInput, McLoadingIndicator, McOption, McSelect, McTable } from '@maersk-global/mds-react-wrapper';
import { useParams } from 'react-router-dom';
import useUsers from '../capacity/hooks/useUsers';
import { User } from '../common/service/users/types';
import { columns } from './consts';
import { AppRoutes } from '../../config/routes';
import { useRedirectToRoute } from '../common/hooks/useRedirectToRoute';
import { FormattedMessage, useIntl } from 'react-intl';
import { getTerminalByCode, terminals } from '../common/helpers/terminals';
import useAuth from '../common/hooks/useAuth';
import AddUserDrawer from './AddUserDrawer/AddUserDrawer';
import EditUserDrawer from './EditUserDrawer/EditUserDrawer';

const UsersContainer: FunctionComponent<{ isGlobal?: boolean }> = ({ isGlobal }) => {
  const { userData } = useAuth();
  const { formatMessage } = useIntl();
  const { users, fetchUsers, fetchGlobalUsers, terminalCode, setTerminalCode, loading } = useUsers();
  const [query, setQuery] = useState<string>('');
  const [addingUser, setAddingUser] = useState(false);
  const [editUser, setEditUser] = useState<User | null>(null);
  const redirectToRoute = useRedirectToRoute();
  const { id } = useParams<{ id: string }>();

  const fetchData = () => {
    if (isGlobal) {
      fetchGlobalUsers();
    } else {
      fetchUsers(id!);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- by design
  }, [id]);

  const handleSelectChange = async (selectedCode: string) => {
    if (!selectedCode) return;
    setTerminalCode(selectedCode);
    redirectToRoute(AppRoutes.Users, { id: selectedCode }, { replace: true });
  };

  const filterByQuery = () => {
    return users.filter(
      (user) =>
        user.name.toLowerCase().includes(query.toLowerCase()) || user.email.toLowerCase().includes(query.toLowerCase()),
    );
  };

  // @ts-ignore
  return (
    <div className="UsersContainer">
      {Boolean(addingUser) && (
        <AddUserDrawer
          selectedTerminal={terminalCode}
          open
          isGlobal={Boolean(isGlobal)}
          setOpen={() => setAddingUser(false)}
        />
      )}

      {Boolean(editUser) && (
        <EditUserDrawer
          open
          isGlobal={Boolean(isGlobal)}
          setOpen={() => setEditUser(null)}
          selectedTerminal={terminalCode}
          selectedUser={editUser}
        />
      )}

      {!isGlobal && (
        <>
          <div className="UsersContainer__dropdown-wrapper">
            <FormattedMessage id="updateRuleDrawerFormTerminal" />
            <div className="UsersContainer__dropdown-wrapper__content">
              <McSelect
                className="UsersContainer__dropdown"
                hiddenlabel
                placeholder="Select terminal"
                value={terminalCode}
                optionselected={(e) => {
                  handleSelectChange((e.target as HTMLInputElement).value);
                }}
                name="terminal"
              >
                {userData?.terminalCodes &&
                  terminals
                    .filter((terminal) => userData.terminalCodes.includes(terminal.code))
                    .map((terminal) => (
                      <McOption key={terminal.code} value={terminal.code}>
                        {terminal.name}
                      </McOption>
                    ))}
              </McSelect>
            </div>
          </div>
          <h1 className="UsersContainer__heading">
            {terminalCode ? `${getTerminalByCode(terminalCode)?.name} (${getTerminalByCode(terminalCode)?.code})` : ''}
          </h1>
        </>
      )}

      <h2 className="UsersContainer__title">
        {formatMessage({ id: isGlobal ? 'globalUsersManagement' : 'usersManagement' })}
      </h2>

      {loading ? (
        <McLoadingIndicator label={formatMessage({ id: 'fetchingData' })} />
      ) : (
        <div className="UsersContainer__table-wrapper">
          <div className="UsersContainer__search-wrapper">
            <McInput
              className="UsersContainer__search-wrapper__input"
              placeholder={formatMessage({ id: 'userSearchPlaceholder' })}
              icon="magnifying-glass"
              hiddenlabel
              onInput={(e) => setQuery((e.target as HTMLInputElement).value)}
            />
            <McButton
              label={formatMessage({ id: 'addUser' })}
              appearance="primary"
              icon="plus"
              variant="outlined"
              click={() => setAddingUser(true)}
            />
          </div>

          {/*//@ts-ignore*/}
          <McTable columns={columns} data={filterByQuery()}>
            {filterByQuery().map((row) => (
              <div key={`${row.id}_action`} slot={`${row.id}_action`}>
                <McButton
                  fit="small"
                  variant="plain"
                  appearance="primary"
                  icon="trash"
                  onClick={() => setEditUser(row)}
                >
                  {formatMessage({ id: 'delete' })}
                </McButton>
              </div>
            ))}
          </McTable>
        </div>
      )}
    </div>
  );
};

export default UsersContainer;
