import { Maybe } from '../../../common/types';
import { CapacityDataType } from '../../types';

type FormData = {
  moves: number;
  terminalCode: string;
  weeks: string[];
  moveCountStretchPercentage: number;
  moveCountCriticalPercentage: number;
  capacityDataType: CapacityDataType;
  yardCapacity: number;
  reeferPlugs: number;
  imoUnits: number;
  imoUnitsDescription: Maybe<string>;
  oogUnits: number;
  selectedWeeks: string[];
};

export type WeeklyValueDtoSOC = {
  moves: number;
  terminalCode: string;
  weeks: string[];
  moveCountStretchPercentage: number;
  moveCountCriticalPercentage: number;
  capacityDataType: CapacityDataType;
  entityType: 'SOC';
};

export type WeeklyValueDtoYard = {
  yardCapacity: number;
  reeferPlugs: number;
  imoUnits: number;
  imoUnitsDescription: Maybe<string>;
  oogUnits: number;
  terminalCode: string;
  weeks: string[];
  capacityDataType: CapacityDataType;
  entityType: 'YARD';
};

export type WeeklyValueDto = WeeklyValueDtoSOC | WeeklyValueDtoYard;

export const createWeeklyValueDto = (formData: FormData, type: 'soc' | 'yard'): WeeklyValueDto => {
  if (type === 'soc') {
    return {
      moves: formData.moves,
      moveCountStretchPercentage: formData.moveCountStretchPercentage,
      moveCountCriticalPercentage: formData.moveCountCriticalPercentage,
      weeks: formData.selectedWeeks,
      capacityDataType: formData.capacityDataType,
      terminalCode: formData.terminalCode,
      entityType: 'SOC',
    };
  }
  return {
    yardCapacity: formData.yardCapacity,
    reeferPlugs: formData.reeferPlugs,
    imoUnits: formData.imoUnits,
    imoUnitsDescription: formData.imoUnitsDescription,
    oogUnits: formData.oogUnits,
    terminalCode: formData.terminalCode,
    weeks: formData.selectedWeeks,
    capacityDataType: formData.capacityDataType,
    entityType: 'YARD',
  };
};
