import { httpClient } from '../http-client';
import { OCCResponse, UpdateOCCRequest } from './types';

export const occService = () => {
  const client = httpClient();
  const BASE_URL = '/v1/overlapping-connections-capacity';

  return {
    fetchOCC: async ({ terminal }: { terminal: string }): Promise<OCCResponse | null> => {
      const url = `${BASE_URL}/${terminal}`;
      try {
        const result = await client.get<OCCResponse>(url);
        return result.data;
      } catch (e) {
        logger(`Error calling API: ${e}`);
        return null;
      }
    },
    updateOCC: async ({ data }: UpdateOCCRequest): Promise<OCCResponse | null> => {
      const url = `${BASE_URL}/${data.terminalCodeAndType}`;
      try {
        const result = await client.post<OCCResponse>(url, data);
        return result.data;
      } catch (e) {
        logger(`Error calling API: ${e}`);
        return null;
      }
    },
  };
};

const logger = (message: string): void => console.error(`[OCC Service] ${message}`);
