import { McButton, McTextarea } from '@maersk-global/mds-react-wrapper';
import { Field } from 'formik';
import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import Drawer from '../../../common/components/Drawer/Drawer';
import { FormikInputFieldProps } from '../../../common/components/FormikInputField/FormikInputField';
import FormattedMessage from '../../../common/FormattedMessage';
import './IMODescriptionDrawer.scss';
import { Back } from '../../../common/icons';

const IMODescriptionDrawer = ({ open, setOpen }) => {
  const { formatMessage } = useIntl();

  return (
    <Drawer title="" open={open} setOpen={setOpen} noFooter noTitle>
      <div className="IMODrawerContent">
        <div className="IMODrawerContent__title">
          <span className="IMODrawerContent__title__back" onClick={() => setOpen(false)}>
            <Back />
          </span>
          <h3>{formatMessage({ id: 'imoDescriptionHeader' })}</h3>
        </div>
        <div className="IMODrawerContent__textareawrapper">
          <Field
            label=""
            type="text"
            name="imoUnitsDescription"
            placeholder={formatMessage({ id: 'imoDescriptionPrompt' })}
            rows={20}
            component={FormikTextarea}
          />
        </div>
        <div className="IMODrawerFooter">
          <McButton type="button" appearance="neutral" click={() => setOpen(false)}>
            <FormattedMessage id="back" />
          </McButton>
        </div>
      </div>
    </Drawer>
  );
};

const FormikTextarea: FunctionComponent<FormikInputFieldProps> = ({ field, form, error, ...inputProps }) => {
  const onInput = async (e: InputEvent) => {
    await form.setFieldValue(field.name, (e.target as HTMLInputElement).value);
  };

  return (
    <McTextarea
      name={field.name}
      // @ts-ignore
      value={field.value ?? ''}
      input={onInput}
      {...inputProps}
    />
  );
};

export default IMODescriptionDrawer;
