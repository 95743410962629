import { HttpClient } from '../HttpClient';
import { AxiosResponse } from 'axios';
import { OctwUrls } from '../urls';
import { createOctwRule } from '../../fixtures/OctwRule.fixture';
import { createApiAxiosResponse } from '../../fixtures/Api.fixture';
import { UpdateRuleDto } from '../../features/octw/dto/UpdateRuleDto';
import { DefaultValueDto } from '../../features/capacity/containers/dto/DefaultValueDto';
import { WeeklyValueDto } from '../../features/capacity/containers/dto/WeeklyValueDto';
import { UpdateOccDto } from '../../features/capacity/containers/dto/OccDto';

export type OctwRule = {
  hubId: string;
  apmtHubId: string;
  ruleName: string;
  hubName: string;
  deOperationalConnectionTimeWindow: string;
  exOperationalConnectionTimeWindow: string;
};

const USE_MOCKS = false;

export default class OctwClient extends HttpClient {
  async fetchRules(): Promise<AxiosResponse<OctwRule | OctwRule[]>> {
    if (USE_MOCKS) {
      return new Promise((resolve) => {
        const rule1 = createOctwRule();
        setTimeout(() => {
          resolve(createApiAxiosResponse([rule1]));
        }, 300);
      });
    }
    const urlForRules = OctwUrls.getUrlForSearch();
    // @TODO: This should be changed on backend. We should not retrieve information by POST method!
    const response = await this.post<AxiosResponse<OctwRule | OctwRule[]>>(urlForRules, {});
    return response;
  }

  async getCapacityForTerminal(code: string): Promise<AxiosResponse<any>> {
    const url = `/v1/capacity/${code}`;

    try {
      // TODO: remove this function
      return new Promise((resolve) => {});
      //return await this.get<AxiosResponse<any>>(url);
    } catch (error) {
      console.error(`Failed to fetch capacity for terminal ${code}:`, error);
      throw error;
    }
  }

  async updateRule(dto: UpdateRuleDto): Promise<void> {
    const url = OctwUrls.getUrlForRuleUpdate();
    await this.post(url, dto);
  }
  async updateDefaultValue(dto: DefaultValueDto): Promise<void> {
    const url = OctwUrls.getUrlForDefaultValueUpdate();
    await this.post(url, dto);
  }
  async createDefaultValue(dto: DefaultValueDto): Promise<void> {
    const url = '/v1/capacity/template/create';
    await this.post(url, dto);
  }
  async deleteDefaultValue(id: string): Promise<void> {
    const url = `/v1/capacity/template/${id}/delete`;
    await this.post(url, {});
  }
  async updateOccValues(dto: UpdateOccDto): Promise<void> {
    const url = `/v1/overlapping-connections-capacity/${dto.terminalCodeAndType}`;
    await this.post(url, dto);
  }
  async updateWeeklyValue(dto: WeeklyValueDto): Promise<void> {
    const url = OctwUrls.getUrlForWeeklyValueUpdate();
    await this.post(url, dto);
  }

  async getOccCapacityForTerminal(code: string) {
    const url = `/v1/overlapping-connections-capacity/${code}`;

    try {
      return await this.get<AxiosResponse<any>>(url);
    } catch (error) {
      console.error(`Failed to fetch capacity for terminal ${code}:`, error);
      throw error;
    }
  }

  async getUser() {
    const url = `/v1/user`;

    try {
      return await this.get<AxiosResponse<any>>(url);
    } catch (error) {
      console.error(`Failed to fetch user data`, error);
      throw error;
    }
  }
}
