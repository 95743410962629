export function modifyDate(inputDate, action) {
  // Parse the date as UTC
  const date = new Date(`${inputDate}T00:00:00Z`);

  if (isNaN(date)) {
    throw new Error('Invalid date format. Please use YYYY-MM-DD format.');
  }

  if (action === 'add') {
    date.setUTCDate(date.getUTCDate() + 1);
  } else if (action === 'remove') {
    date.setUTCDate(date.getUTCDate() - 1);
  } else {
    throw new Error("Invalid action. Use 'add' or 'remove'.");
  }

  return date.toISOString().split('T')[0];
}

export function modifyDateWeek(inputDate, action) {
  const date = new Date(`${inputDate}T00:00:00Z`);

  if (isNaN(date)) {
    throw new Error('Invalid date format. Please use YYYY-MM-DD format.');
  }

  if (action === 'add') {
    date.setDate(date.getDate() + 7);
  } else if (action === 'remove') {
    date.setDate(date.getDate() - 7);
  } else {
    throw new Error("Invalid action. Use 'add' or 'remove'.");
  }

  const formattedDate = date.toISOString();
  return formattedDate;
}
