import { EditOccFormShape } from '../EditOccValuesDrawer/EditOccValuesDrawer';
import { mapLabelToCapacityType } from '../utils/capacityTypeMapper';

export type UpdateOccDto = {
  vesselPairLimits: number;
  from: string;
  to: string;
  terminalCodeAndType: string;
};

export const createOccDto = (formData: EditOccFormShape): UpdateOccDto => {
  return {
    vesselPairLimits: formData.vesselPairLimits,
    from: mapLabelToCapacityType(formData.from),
    to: mapLabelToCapacityType(formData.to),
    terminalCodeAndType: formData.terminalCode + '-' + formData.dateType,
  };
};
