import { useGetCurrentRoute } from './useGetCurrentRoute';
import { AppRoutes } from '../../../config/routes';
import { useIntl } from './useIntl';
import { PartialRecord, Translation } from '../types';

const useGetTopbarTitle = () => {
  const getCurrentRoute = useGetCurrentRoute();
  const { formatMessage } = useIntl();

  const currentRoute = getCurrentRoute();
  const mapRouteToTopbarTitleId: PartialRecord<AppRoutes, keyof Translation> = {
    [AppRoutes.OctwRules]: 'octwPageTitle',
    [AppRoutes.CapacityId]: 'capacityPageTitle',
    [AppRoutes.Users]: 'usersPageTitle',
    [AppRoutes.GlobalUsers]: 'globalUsersPageTitle',
  };

  return () => {
    const copyId = mapRouteToTopbarTitleId[currentRoute];
    if (!copyId) {
      return '';
    }
    return formatMessage({
      id: copyId,
    });
  };
};

export default useGetTopbarTitle;
