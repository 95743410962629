import { ErrorMessage, Field } from 'formik';
import React, { useEffect } from 'react';
import FormikInputField from '../../../common/components/FormikInputField/FormikInputField';
import { MessageBubble } from '../../../common/icons';
import './IMOInputField.scss';
import IMODescriptionDrawer from './IMODescriptionDrawer';

const IMOInputField = ({ isDrawerOpen }: { isDrawerOpen: boolean }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  // close the IMO Drawer when the main drawer is being closed
  useEffect(() => {
    if (!isDrawerOpen) {
      setDrawerOpen(false);
    }
  }, [isDrawerOpen]);

  return (
    <>
      <div className="IMOInputField">
        <Field label="IMO" type="number" name="imoUnits" component={FormikInputField} />
        <ErrorMessage name="imoUnits" component="div" />
        <span className="IMOInputField__addon" onClick={() => setDrawerOpen(true)}>
          <MessageBubble className="IMOInputField__addon__icon" />
        </span>
      </div>
      <IMODescriptionDrawer open={drawerOpen} setOpen={setDrawerOpen} />
    </>
  );
};

export default IMOInputField;
