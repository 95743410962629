import { McIcon } from '@maersk-global/mds-react-wrapper';
import { FunctionComponent } from 'react';
import { useClickOutside } from '../../hooks/useClickOutside';
import { SideNavItem } from './SideNav';

const ChildrenPopup: FunctionComponent<{ items: SideNavItem[]; onClose: Function }> = ({ items, onClose }) => {
  const { ref } = useClickOutside({
    toggler: () => onClose((prev) => !prev),
    state: true,
  });

  return (
    <div className="SideNav__children-wrapper" ref={ref}>
      {items.map((item) => {
        return (
          <div
            className="SideNav__children-wrapper__item"
            key={item.title}
            onClick={() => {
              item.onClick?.();
              onClose();
            }}
          >
            <McIcon icon={item.icon} />
            {item.title}
          </div>
        );
      })}
    </div>
  );
};

export default ChildrenPopup;
