import { AxiosRequestConfig, Method } from 'axios';
import * as https from 'https';
import axiosInstance from './axiosSetup';
import { DependencyContainer } from './DependencyContainer';
import RequestConfigProvider from './RequestConfigProvider';

export interface HttpClientProtocol {
  get<T>(endpoint: string): Promise<T>;
  post<T>(endpoint: string, data?: any, configOverwrite?: AxiosRequestConfig): Promise<T>;
}

export class HttpClient implements HttpClientProtocol {
  constructor(protected readonly factory: DependencyContainer) {
    this.factory = factory;
  }

  async get<T>(endpoint: string, configOverwrite?: AxiosRequestConfig): Promise<T> {
    const config = await this.getAxiosRequestConfig('GET', endpoint, configOverwrite);
    return axiosInstance.get(endpoint, config as AxiosRequestConfig);
  }

  async post<T>(endpoint: string, data: any, configOverwrite?: AxiosRequestConfig): Promise<T> {
    const config = await this.getAxiosRequestConfig('POST', endpoint, configOverwrite);
    return await axiosInstance.post(endpoint, data, config as AxiosRequestConfig);
  }

  async put<T>(endpoint: string, data: any, configOverwrite?: AxiosRequestConfig): Promise<T> {
    const config = await this.getAxiosRequestConfig('PUT', endpoint, configOverwrite);
    return await axiosInstance.put(endpoint, data, config as AxiosRequestConfig);
  }

  private async getAxiosRequestConfig(
    method: Method,
    endpoint: string,
    configOverwrite?: AxiosRequestConfig,
  ): Promise<AxiosRequestConfig> {
    const token = await RequestConfigProvider.getBearerToken();
    const headersWithToken = token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : undefined;

    const headers = {
      ...headersWithToken,
    };

    const config: AxiosRequestConfig = {
      url: endpoint,
      method,
      timeout: RequestConfigProvider.getTimeout(),
      httpsAgent: new https.Agent({
        rejectUnauthorized: false,
      }),
      headers,
      ...configOverwrite,
    };

    return Promise.resolve(config);
  }
}
