import { CapacityDataType } from '../../types';

type FormData = {
  id: string;
  startDate: string;
  moves: number;
  terminalCode: string;
  moveCountStretchPercentage: number;
  moveCountCriticalPercentage: number;
  capacityDataType: CapacityDataType;
  yardCapacity: number;
  reeferPlugs: number;
  imoUnits: number;
  imoUnitsDescription: string;
  oogUnits: number;
};

export type DefaultValueDtoSOC = {
  id: string;
  startDate: string;
  moves: number;
  terminalCode: string;
  moveCountStretchPercentage: number;
  moveCountCriticalPercentage: number;
  capacityDataType: CapacityDataType;
  entityType: 'SOC';
};

export type DefaultValueDtoYard = {
  id: string;
  startDate: string;
  yardCapacity: number;
  reeferPlugs: number;
  imoUnits: number;
  imoUnitsDescription: string;
  oogUnits: number;
  terminalCode: string;
  capacityDataType: CapacityDataType;
  entityType: 'YARD';
};

export type DefaultValueDto = DefaultValueDtoYard | DefaultValueDtoSOC;

export const createDefaultValueDto = (formData: FormData, type: 'soc' | 'yard'): DefaultValueDto => {
  if (type === 'soc') {
    return {
      id: formData.id,
      startDate: formData.startDate,
      moves: formData.moves,
      moveCountStretchPercentage: formData.moveCountStretchPercentage,
      moveCountCriticalPercentage: formData.moveCountCriticalPercentage,
      capacityDataType: formData.capacityDataType,
      terminalCode: formData.terminalCode,
      entityType: 'SOC',
    };
  }
  return {
    id: formData.id,
    startDate: formData.startDate,
    yardCapacity: formData.yardCapacity,
    reeferPlugs: formData.reeferPlugs,
    imoUnits: formData.imoUnits,
    imoUnitsDescription: formData.imoUnitsDescription,
    oogUnits: formData.oogUnits,
    terminalCode: formData.terminalCode,
    capacityDataType: formData.capacityDataType,
    entityType: 'YARD',
  };
};
