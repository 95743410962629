export enum CapacityDataType {
  Total = 'TOTAL',
  Maersk = 'MAERSK',
  Hapaglloyd = 'HAPAGLLOYD',
}

export type CapacityEntityEntry = {
  reeferPlugs: number;
  moves: number;
  yardCapacity: number;
  imoUnits: number;
  oogUnits: number;
  overwrite?: boolean;
  moveCountStretchPercentage: number;
  moveCountCriticalPercentage: number;
  capacityDataType: CapacityDataType;
};

export type CapacityEntityEntrySOC = {
  moves: number;
  moveCountStretchPercentage: number;
  moveCountCriticalPercentage: number;
  capacityDataType: CapacityDataType;
  overwrite?: boolean;
  entityType: 'SOC';
};

export type CapacityEntityEntryYard = {
  reeferPlugs: number;
  yardCapacity: number;
  imoUnits: number;
  imoUnitsDescription?: string;
  oogUnits: number;
  overwrite?: boolean;
  capacityDataType: CapacityDataType;
  entityType: 'YARD';
};

export type CapacityEntity = {
  id: string;
  terminalCodeAndStartDate: string;
  terminalCode: string;
  startDate: string;
  date: string;
  entries: (CapacityEntityEntrySOC | CapacityEntityEntryYard)[];
};

export type CapacityEntitiesResponse = {
  capacityEntities: CapacityEntity[];
  capacityTemplateEntities: CapacityEntity[];
};

export type Changelog = {
  id: string;
  entityId: string;
  entityType: string;
  capacityDataType: CapacityDataType;
  timestamp: string;
  instigatorId: string;
  instigatorName: string;
  instigatorMail: string;
  fieldName: string;
  oldValue: string;
  newValue: string;
};

// @TODO: Change it!
export type OccResponse = any;
