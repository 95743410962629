import React, { FunctionComponent, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { formattedNumber, formattedNumberToInt } from '../../../../common/helpers/utils';
import { Changelog } from '../../../types';
import { mapFieldToDisplay } from './helpers';

export type StepProps = {
  entry: Changelog;
};

const timeInSecond = {
  MINUTE: 60,
  HOUR: 60 * 60,
  DAY: 24 * 60 * 60,
  MONTH: 30 * 24 * 60 * 60,
};

const Step: FunctionComponent<StepProps> = ({ entry }) => {
  const { formatDate, formatRelativeTime } = useIntl();

  const relativeTime = useMemo(() => {
    const date = new Date(entry.timestamp);
    const now = new Date();
    const diff = Math.abs(+date - +now) / 1000;

    if (diff < timeInSecond.MINUTE) {
      return formatRelativeTime(Math.ceil(-diff), 'second');
    } else if (diff < timeInSecond.HOUR) {
      return formatRelativeTime(-Math.ceil(diff / timeInSecond.MINUTE), 'minute');
    } else if (diff < timeInSecond.DAY) {
      return formatRelativeTime(-Math.ceil(diff / timeInSecond.HOUR), 'hour');
    } else if (diff < timeInSecond.MONTH) {
      return formatRelativeTime(-Math.ceil(diff / timeInSecond.DAY), 'day');
    } else {
      return formatDate(date, { dateStyle: 'medium' });
    }
  }, [entry.timestamp]);

  return (
    <div className="ChangelogStep">
      <div className="ChangelogStep__text-wrapper">
        <div>
          {entry.fieldName === 'override' ? (
            <span className="ChangelogStep__field">{mapFieldToDisplay[entry.fieldName]}</span>
          ) : (
            <>
              <span className="ChangelogStep__field">'{mapFieldToDisplay[entry.fieldName]}'</span> updated from{' '}
              <span className="ChangelogStep__value">{formattedNumber(formattedNumberToInt(entry.oldValue))}</span> to{' '}
              <span className="ChangelogStep__value">{formattedNumber(formattedNumberToInt(entry.newValue))}</span>
            </>
          )}
        </div>
        <div className="ChangelogStep__author-date">
          {entry.instigatorName} &#x2022; <span>{relativeTime}</span>
        </div>
      </div>
    </div>
  );
};

export default Step;
