import { useLocation, matchPath } from 'react-router-dom';
import { AppRoutes } from '../../../config/routes';

export const useGetCurrentRoute = (): (() => AppRoutes) => {
  const location = useLocation();
  const routes = Object.values(AppRoutes)
    .reverse()
    .map((route) => ({ path: route }));

  return () => {
    const found = routes.find((route) => matchPath(route, location.pathname));
    if (!found) {
      throw new Error(`Route for pathname ${location.pathname} not found`);
    }
    return found.path as AppRoutes;
  };
};
