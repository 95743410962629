import { McIcon } from '@maersk-global/mds-react-wrapper';
import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import './steps.scss';

const EmptyChangelog: FunctionComponent<{}> = ({}) => {
  const { formatMessage } = useIntl();

  return (
    <div className="EmptyChangelog">
      <McIcon icon="receipt" />
      {formatMessage({ id: 'emptyChangelog' })}
    </div>
  );
};

export default EmptyChangelog;
